import React, { useState, useEffect, useRef  } from 'react';
import { httpClient } from '../lib/api-factory';
import { useRecoilValue, selectorFamily } from 'recoil';

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Badge,
    Image,
    Tabs, 
    Tab
} from 'react-bootstrap';

import Hidden from '@material-ui/core/Hidden';

//https://www.npmjs.com/package/react-image-gallery
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const statusBadge = (status) => {
    var _s = status ? status.toLowerCase() : "success";
    if(["common", "low risk"].includes(_s)) return "success";
    if(["vulnerable"].includes(_s)) return "warning";
    if(["endangered"].includes(_s)) return "danger";
    return "success";
};

const getSubjectQuery = selectorFamily({
    key: 'getSubjectQuery',
    get: id => async () => {
        const response = await httpClient().get(`/subject/${id}`);
        return response.data;
    }
});

export default (props) => {
    var {params} = props.match;

    const [currentImage, setCurrentImage] = useState({});
    const imageGallery = useRef(null);
    const subject = useRecoilValue(getSubjectQuery(params.id)); 

    useEffect(() => {
        console.log("useEffect subject called");
    }, []);

    const currentImageHandler = (e) => {
        var index = imageGallery?.current?.getCurrentIndex();
        setCurrentImage(imageGallery?.current?.props?.items[index] || {});
    };

    //https://picsum.photos/id/977/800/500/

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="#/">
                    Categories
                </Breadcrumb.Item>
                <Breadcrumb.Item href={`#/subcategory/${subject.subid}`}>
                    {subject.subcategory}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{subject.name}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="app-page">
                <Container fluid className="page-container">
                    <Row>
                        <Col span={12}>
                            <div>
                                <h2>
                                    {subject.name}
                                </h2>
                            </div> 
                        </Col>
                    </Row>

                    <Row>
                        <Col sm ={12} md={4}>
                            <Hidden xsDown>
                                <div style={{backgroundColor: "#fffdcd", padding: "10px 0 10px 10px"}}>
                                    <div>
                                        <b>Scientific name:</b> {subject.scientificName || "Unknown"}
                                    </div>

                                    <div>
                                        <b>Status:</b>
                                        <Badge variant={statusBadge(subject.status)}>
                                            {subject.status || "Unknown"}
                                        </Badge>
                                    </div>

                                    <div>
                                        <b>Habitat:</b> {subject.habitat || "Unknown"}
                                    </div>

                                    <div>
                                        <b>Length:</b> {subject.length || "Unknown"}
                                    </div>

                                    <div style={{paddingTop: "20px"}}>
                                        <Image src={subject.rangmeMap || "images/location_blank.jpg"} alt="map" style={{width: "50%", height: "50%"}} fluid />
                                        {/*https://place-hold.it/525x525  todo: change to S3 location  */}
                                    </div>
                                </div>
                            </Hidden>

                            <Hidden smUp>
                                <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
                                    <Tab eventKey="home" title="Description" style={{backgroundColor: "#fffdcd"}}>
                                        <div>
                                            <b>Scientific name:</b> {subject.scientificName || "Unknown"}
                                        </div>

                                        <div>
                                            <b>Status:</b>
                                            <Badge variant={statusBadge(subject.status)}>
                                                {subject.status || "Unknown"}
                                            </Badge>
                                        </div>

                                        <div>
                                            <b>Habitat:</b> {subject.habitat || "Unknown"}
                                        </div>

                                        <div>
                                            <b>Length:</b> {subject.length || "Unknown"}
                                        </div>
                                    </Tab>

                                    <Tab eventKey="profile" title="Distribution" style={{backgroundColor: "#fffdcd"}}>
                                        <div style={{paddingTop: "20px"}}>
                                            <Image src={subject.rangmeMap || "https://place-hold.it/525x525"} alt="map" style={{width: "50%", height: "50%"}} fluid />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Hidden>
                        </Col>

                        <Col sm={12} md={8}>
                            <div style={{maxWidth: "800px", backgroundColor: "#E9ECEF", padding: "10px"}}>
                                { subject.images && subject.images.length > 0 ?
                                <ImageGallery 
                                    items={subject.images} 
                                    thumbnailPosition="left" 
                                    showFullscreenButton={false}
                                    ref={imageGallery}
                                    onImageLoad={currentImageHandler}
                                    onSlide={currentImageHandler}
                                    showPlayButton={true}
                                />
                                :
                                <div>
                                    <Image src="https://place-hold.it/800x500" alt="Image not available" fluid />
                                </div>
                                }
                            </div>
                            
                            <div style={{fontSize: ".85em", paddingTop: "4px"}}>
                                { currentImage.photoBy ? `Photo by: ${currentImage.photoBy}` : "Uncredited photo" }
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <div style={{paddingTop: "30px"}}>
                                <h5>Description</h5>
                            </div>

                            <div style={{width: "80%"}}
                                dangerouslySetInnerHTML={{
                                    __html: subject.description || "Description unknown"
                                }}>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}