import axios from 'axios';

export const httpClient = () => {
    var environment = process.env.REACT_APP_ENVIRONMENT;

    console.log("ENV", environment);

    var api_url = environment === "development" ?
        "http://localhost:3101" :
        "https://api.discoverthewild.org/v1";

    const ax = axios.create({
        baseURL: api_url
    });

    return ax;
};