import React from 'react';
import styled from 'styled-components';

const Phantom = styled.div({
    display: 'block',
    padding: '10px',
    height: '40px',
    width: '100%'
});

const Footer = styled.div({
    backgroundColor: "#5E9DDF",
    textAlign: "center",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    width: "100%"
});


const StickyFooter = (props) => {
    return (
        <div>
            <Phantom />
            <Footer>
                { props.children }
            </Footer>
        </div>
    )
};

export default StickyFooter