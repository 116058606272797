import React, { Component } from 'react';
import { Route, HashRouter } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
//import ReactGA from 'react-ga';
import './index.css';
//import { LinkContainer } from 'react-router-bootstrap';
import StickyFooter from './components/sticky-footer';

import { RecoilRoot } from 'recoil';

//bootstrap
//import Form from 'react-bootstrap/Form';
//import FormControl from 'react-bootstrap/FormControl';
import { 
    //Button, 
    Navbar 
} from 'react-bootstrap';

//screens
import Categories from './screens/categories';
import SubCategory from './screens/subcategory';
import Subject from './screens/subject';

//https://www.npmjs.com/package/react-ga
//https://medium.com/brownbag/add-google-analytics-to-create-react-app-project-with-react-router-v4-f12b947262fc
/*const initializeReactGA = () => {
    ReactGA.initialize('UA-7618665-1'); //actual id, not called yet
    ReactGA.pageview('/');
}*/

const getRoutes = (auth) => {
    return [
        {
            exact: true,
            path: "/",
            component: Categories
        },
        {
            path: "/subcategory/:subid",
            component: SubCategory
        },
        {
            path: "/subject/:id",
            component: Subject
        }
    ];
}

const queryCache = new QueryCache();

class App extends Component {
    render() {
        return (
            <RecoilRoot>
                <React.Suspense fallback={<div>Loading...</div>}>
                    <ReactQueryCacheProvider queryCache={queryCache}>
                    <div className="App">
                        <HashRouter hashType="slash">
                            <div>
                                <div>
                                    <Navbar bg="light" variant="light" expand="lg" className="app-nav-bar">
                                        <Navbar.Brand href="/" className="app-name-font app-name-main">
                                            Discover The Wild
                                        </Navbar.Brand>

                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                                        <Navbar.Collapse id="basic-navbar-nav">
                                            {/*<Nav className="mr-auto">
                                                <LinkContainer to="/">
                                                    <Nav.Link>Categories</Nav.Link>
                                                </LinkContainer>
                                            </Nav>*/}
                                            {/*<div style={{width: "70%"}}></div>

                                            <Form inline>
                                                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                                                <Button variant="outline-primary" className="app-nav-bar-search-button">Search</Button>
                                            </Form>*/}
                                        </Navbar.Collapse>
                                    </Navbar>
                                </div>

                                <div className="header-base"></div>

                                <div>
                                    {getRoutes().map((route, i) =>
                                        <Route key={i.toString()} {...route} />
                                    )}
                                </div>
                            </div>
                        </HashRouter>

                        <StickyFooter>
                            <p style={{fontSize: ".75em", color: "#fff"}} className="ml-auto">
                                &copy; Discover The Wild (2006 - {new Date().getFullYear()})
                            </p>
                        </StickyFooter>
                    </div>
                    </ReactQueryCacheProvider>
                </React.Suspense>
            </RecoilRoot>
        );
    }
}

export default App;
