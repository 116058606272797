import React, { useEffect  } from 'react';
import { httpClient } from '../lib/api-factory';

import {
    Card,
    Container,
    Row,
    Col,
    DropdownButton,
    Dropdown,
    Jumbotron
} from 'react-bootstrap';

import { useRecoilState, useRecoilValue, selector } from 'recoil';
import { currentSelectionsAtom } from '../atoms/tracker-atoms';
import { useHistory } from 'react-router-dom';

const getCategoriesQuery = selector({
    key: 'getCategoriesQuery',
    get: async () => {
        const response = await httpClient().get(`/category-data`);
        return response.data;
    }
});

export default (props) => {
    const [_, setCurrentSelections] = useRecoilState(currentSelectionsAtom);
    const categoriesData = useRecoilValue(getCategoriesQuery); 
    let history = useHistory();


    useEffect(() => { 
        console.log("LOADED", categoriesData);
    }, []);

    const subCategoryChange = (e) => {
        var selections = JSON.parse(e);
        setCurrentSelections(selections);
        history.push(`/subcategory/${selections.subcategoryid}`);
    };

    return (
        <>
            <br />

            <Container>
                <Row>
                    <h1 style={{textAlign: "left", paddingLeft: "30px"}}>
                        Welcome to <span className="app-name-font">Discover The Wild</span>
                    </h1>&copy;
                </Row>

                <Row xs={1} md={3} lg={6}>
                { categoriesData?.map((c, i) => 
                    <Col key={`cat-${i}`} style={{textAlign: "center"}}>
                        <Card className="category-card">
                            <Card.Img variant="top" src={`thumbs/${c.category}.jpg`} />
                            
                            <Card.Body style={{backgroundColor: "#579CDF", padding: "0"}}>
                                <Card.Title>
                                    <DropdownButton title={c.category} variant="link" className="subCategoryLinkMain" onSelect={subCategoryChange}>
                                        { c.subcategories?.map((sub, i) =>
                                            <Dropdown.Item 
                                                key={`sub_${sub.id}`}
                                                eventKey={JSON.stringify({
                                                    category: c.category,
                                                    subcategory: sub.name,
                                                    subcategoryid: sub.id
                                                })}>
                                                {sub.name}
                                            </Dropdown.Item>
                                        )}
                                    </DropdownButton>
                                </Card.Title>

                                <Card.Text style={{padding: "5px 5px 20px 5px", color: "#c6e4ff"}}>
                                    Select a sub-category above to see a list of {c.category.toLowerCase()}.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
                </Row>
                
                <Row>
                    <hr style={{width: "100%", backgroundColor:"#D9E9F7", marginTop: "30px"}} />

                    <Jumbotron fluid className="intro-text">
                        <Container>
                            <h4>about ~</h4>

                            <p>
                                This site is dedicated to bringing my passion of nature to you. It is a work
                                in progress and will continue over a lifetime.I encourage you to participate in adding to the site. Send "your"
                                animal photographs along with information for that animal and your name. Submissions meeting approval will be added 
                                to the site.
                                <br /><br />
                                There are far too many animals in the world for one person to tackle alone so you will find some of the categories
                                have limited or no animals at all. Keep checking back, more are added all of the time. 
                                <br /><br />
                                Begin by selecting one of the sub-categories above or by using the search.
                            </p>
                        </Container>
                    </Jumbotron>
                </Row>
            </Container>
        </>
    );
}