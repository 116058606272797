import React, { useState, useEffect  } from 'react';
import { NavLink } from 'react-router-dom';
import { httpClient } from '../lib/api-factory';
import { Breadcrumb } from 'react-bootstrap';
import { useRecoilValue, selectorFamily } from 'recoil';
import { currentSelectionsAtom } from '../atoms/tracker-atoms';

//==================
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
//==================

const useStyles = makeStyles((theme) => ({   //GET RID OF IMAGES AND JSON SERVER
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflowX: 'hidden',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper
    },
    gridList: {
      width: "100%",
      height: 500,
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    pagination: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
}));

const getSubCategoriesQuery = selectorFamily({
    key: 'getSubCategoriesQuery',
    get: subid => async () => {
        const response = await httpClient().get(`/wl/${subid}`);
        return response.data;
    }
});

export default (props) => {
    const classes = useStyles();
    var {params} = props.match;

    const [currentSet, setCurrentSet] = useState([]);
    const [page, setPage] = useState(1); 
    const [pageCount, setPageCount] = useState(0); 
    const mySelections = useRecoilValue(currentSelectionsAtom);
    const subCategoriesData = useRecoilValue(getSubCategoriesQuery(params.subid)); 

    useEffect(() => { 
        (async () => {
            try {
                getCurrentSet(1, subCategoriesData);
                setPageCount(Math.ceil(subCategoriesData.length / 10));

                console.log("useEffect sub-categories called");
            }
            catch(error) {

            }
        })();
    }, []);

    const getCurrentSet = (value, dataArray) => {
        var _currentSet = [];
        var _to = value * 10;
        var _from = _to - 10;

        for(let i = _from; i < _to; i++) {
            if(typeof dataArray[i] !== 'undefined') {
                _currentSet.push(dataArray[i]);
            }
        }
        
        setCurrentSet(_currentSet);
    };

    const handleChange = (event, value) => { 
        setPage(value);
        getCurrentSet(value, subCategoriesData);
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="#/">
                    Categories
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{mySelections.subcategory}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="app-page">
                <div><h2>{mySelections.subcategory}</h2></div>

                { pageCount > 0 ? 
                <>
                <div className={classes.root}>
                    <GridList cellHeight={180} className={classes.gridList}>
                        <GridListTile key="Subheader" cols={5} style={{ height: 'auto', width: "100%" }}>
                            <Typography>
                                Browser through the images below. To view more information about the animal you have selected
                                click on the info icon <InfoIcon />. 
                                {/*Replace this text with sub-category descriptions*/}
                            </Typography>
                        </GridListTile>

                        {currentSet.map((tile) => (
                        <GridListTile style={{width: "280px"}} key={`subject_${tile.id}`}>
                            <img src={tile.images[0].thumbnail} alt={tile.name} style={{width: "280px", height: "180px"}} /> 
                            {/*todo: get from data 270x180     "https://picsum.photos/id/977/270/180"        */}

                            <GridListTileBar
                                title={tile.name}
                                subtitle={<span>by: {tile.author || "SBrickner"}</span>}
                                actionIcon={
                                    <NavLink to={`/subject/${tile.id}`} className={classes.icon}>
                                        <InfoIcon />
                                    </NavLink>
                                }
                            />
                        </GridListTile>
                        ))}
                    </GridList>
                </div>

                <div className={classes.pagination}>
                    <Typography>Page: {page} of {pageCount}</Typography>
                    <Pagination count={pageCount} page={page} onChange={handleChange} />
                </div>
                </>
                :
                <div>
                    Nothing yet but keep checking back, or be the first to add something.
                </div>
                }
            </div>
        </>
    );
}